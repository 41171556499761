<template>
    <NuxtPwaManifest />
    <div
        class="bg-gray-800 h-screen flex flex-col w-full min-h-screen max-h-screen">
        <div
            class="flex flex-row justify-between content-center items-center p-3 bg-gray-800">
            <div class="flex flex-col md:flex-row md:items-center">
                <NotifyaLogo classes="text-white text-2xl md:text-3xl" />
                <span class="md:ml-3 text-gray-400 font-bold">
                    · {{ businessesStore.getCurrentBusiness?.name }}
                </span>
            </div>
            <div
                class="flex flex-row px-2 flex-wrap justify-end items-center border border-transparent rounded-md hover:bg-gray-700 cursor-pointer transition duration-100"
                @click="() => toggleMenu()">
                <font-awesome-icon
                    icon="user"
                    class="text-white text-xl my-2" />
                <span class="text-white text-xl ml-2 text-right">{{
                    user.getUser?.name
                }}</span>
            </div>
        </div>
        <div
            class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 overflow-y-auto bg-gray-900 bg-opacity-80 transition-opacity duration-300 ease-in-out"
            :class="{
                'opacity-0 pointer-events-none': !menuOpen,
                'opacity-100 pointer-events-auto': menuOpen,
            }"
            @click="() => toggleMenu(false)">
            <div
                :class="{
                    nothidden: menuOpen,
                }"
                v-if="menuOpen"
                class="absolute right-0 top-0 bg-gray-800 rounded-xl drop-shadow-lg mr-4 mt-16 w-64">
                <div
                    v-for="b in businessesStore.businesses"
                    :class="{
                        'bg-emerald-700':
                            b.id == businessesStore.currentBusiness,
                    }"
                    @click="() => businessesStore.setCurrentBusiness(b.id)"
                    class="mx-3 px-3 my-2 h-auto py-3 flex flex-row align-middle items-center hover:bg-emerald-900 rounded-md cursor-pointer transition duration-100">
                    <font-awesome-icon
                        icon="briefcase"
                        class="text-white text-xl w-5" />
                    <span class="ml-5 text-white"> {{ b.name }} </span>
                </div>
                <div
                    @click="() => toggleDarkMode()"
                    class="flex flex-row mx-3 px-3 my-2 h-auto py-3 align-middle items-center hover:bg-gray-700 rounded-md cursor-pointer">
                    <font-awesome-icon
                        :icon="
                            colorMode.preference === 'light' ? 'moon' : 'sun'
                        "
                        class="text-gray-200 text-xl" />
                    <div class="ml-2">
                        <span class="text-gray-200">
                            {{
                                colorMode.value === "light"
                                    ? "Tmavý režim"
                                    : "Světlý režim"
                            }}
                        </span>
                    </div>
                </div>
                <hr class="bg-gray-700" />
                <div
                    class="mx-3 px-3 my-2 h-auto py-3 flex flex-row align-middle items-center hover:bg-gray-700 rounded-md cursor-pointer transition duration-100"
                    @click="() => $logout()">
                    <font-awesome-icon
                        icon="lock"
                        class="text-white text-xl w-5" />
                    <span class="ml-5 text-white">Odhlásit se</span>
                </div>
            </div>
        </div>
        <div class="flex flex-row flex-grow">
            <div
                class="hidden md:flex flex-col h-full bg-gray-800 p-1 transition-all duration-300 justify-start max-h-full min-h-full">
                <MenuButton title="Domů" icon="home" link="/dash" />
                <MenuButton title="Kalendář" icon="calendar" link="/calendar" />
                <MenuButton title="Objednávky" icon="list" link="/orders" />
                <MenuButton title="Zákazníci" icon="users" link="/customers" />
                <MenuButton title="Ceník" icon="box" link="/price-list" />
                <MenuButton
                    title="Nastavení"
                    icon="gears"
                    link="/settings/profile" />
            </div>
            <div
                class="basis-full md:basis-3/4 flex-grow bg-white dark:bg-gray-900 p-2 md:rounded-tl-xl min-h-full overflow-y-auto mb-20 md:mb-0">
                <slot />
            </div>
        </div>
        <div class="absolute bottom-0 md:hidden flex flex-row h-20 w-screen justify-around items-center p-3 bg-gray-800 z-10">
            <MobileMenuButton title="Domů" icon="home" link="/dash" />
            <MobileMenuButton
                title="Kalendář"
                icon="calendar"
                link="/calendar" />
            <MobileMenuButton title="Objednávky" icon="list" link="/orders" />
            <MobileMenuButton
                title="Zákazníci"
                icon="users"
                link="/customers" />
            <MobileMenuButton title="Ceník" icon="box" link="/price-list" />
            <MobileMenuButton
                title="Nastavení"
                icon="gears"
                link="/settings/profile" />
        </div>
    </div>
</template>

<script lang="ts">
import MenuButton from "~/components/home/MenuButton.vue";
import MobileMenuButton from "~/components/home/MobileMenuButton.vue";
import { useBusinessesStore } from "~~/composables/businesss";
export default defineNuxtComponent({
    setup() {
        const user = useUser();
        const menuOpen = ref(false);
        const businessesStore = useBusinessesStore();
        const colorMode = useColorMode();

        return {
            user,
            menuOpen,
            businessesStore,
            colorMode,
        };
    },
    methods: {
        toggleMenu(value?: boolean) {
            this.menuOpen = value ?? !this.menuOpen;
        },
        toggleDarkMode() {
            this.colorMode.preference =
                this.colorMode.preference === "light" ? "dark" : "light";
        },
    },
    components: {
        MenuButton,
        MobileMenuButton,
    },
});
</script>
<style>
.nothidden {
    animation: showMenu 0.3s ease-out forwards;
}
@keyframes showMenu {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
