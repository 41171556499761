<template>
    <div
        class="px-2 py-2 flex flex-col items-center hover:bg-gray-700 rounded-md cursor-pointer transition-all duration-200"
        @click="route"
        :class="{
            'border border-gray-600': isActive,
            'bg-transparent': !isActive,
        }">
        <font-awesome-icon :icon="icon" class="text-white text-lg" />
        <span class="text-white text-xs mt-1">
            {{ title }}
        </span>
    </div>
</template>
<script>
export default defineNuxtComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
    },
    data() {
        const isActive = this.$route.path.startsWith(this.link);
        return {
            isActive,
        };
    },
    updated() {
        this.isActive = this.$route.path === this.link;
    },
    watch: {
        $route() {
            this.isActive = this.$route.path === this.link;
        },
    },
    methods: {
        route() {
            this.$router.push(this.link);
        },
    },
});
</script>
